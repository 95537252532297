import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    tablet: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6548bd"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      tablet: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  }
})