import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";

const Providers = ({ children }: { children: React.ReactNode }) => {
  const client = new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GQL_URL,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Providers;
