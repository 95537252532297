import "@/styles/globals.css";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { ThemeProvider } from "@mui/material";
import { theme } from "@/utils/theme";
import createEmotionCache from "@/utils/createEmotionCache";
import { CacheProvider } from "@emotion/react";
import Layout from "@/components/Layout";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import { ephesis, poiret } from "@/utils/fonts";
import Head from "next/head";

const clientSideEmotionCache = createEmotionCache();

interface CustomAppProps {
  Component: AppProps["Component"];
  pageProps: AppProps["pageProps"];
  emotionCache: typeof clientSideEmotionCache;
}

export default function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: CustomAppProps) {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <Head>
          <title>Boda Rosa y Adrián</title>
          <meta name="viewport" content="width=device-width" />
          <meta name="description" content="Boda Rosa y Adrian en Acapulco" />
          <meta name="author" content="AdrianMR" />
          <meta
            name="keywords"
            content="Boda Rosa y Adrian, Rosa y Adrian Acapulco, Boda Rosa y Adrian en Acapulco"
          />
          <meta name="robots" content="index,follow" />
          <meta
            name="google-site-verification"
            content="DZnSALds1KhxsAKdQjSn6_UkNUL25LJ3DcX8rExwDbU"
          />
          <link rel="canonical" href="https://rosayadrianwedding.com" />
          <link rel="preconnect" href="https://i.scdn.co" />
          <link rel="preconnect" href="https://encore.scdn.co" />

          <link
            rel="icon"
            type="image/png"
            sizes="48x48"
            href="https://res.cloudinary.com/dzdmvv2zx/image/upload/v1686162856/wedding/icons/uorpatbebeqxpf5opwmj.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="https://res.cloudinary.com/dzdmvv2zx/image/upload/v1686162856/wedding/icons/wpwho08fxsxjc69amayx.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="144x144"
            href="https://res.cloudinary.com/dzdmvv2zx/image/upload/v1686162856/wedding/icons/dildzjmpi3byvpfznyv5.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="https://res.cloudinary.com/dzdmvv2zx/image/upload/v1686162856/wedding/icons/o9nwoxc5baq823aqqb8o.png"
          />
        </Head>
        <Layout titleFont={ephesis.className} bodyFont={poiret.className}>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </CacheProvider>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  metric;
}
