import { FunctionComponent, ReactNode } from "react";
import Providers from "./Providers";

interface BaseLayoutProps {
  titleFont: any;
  bodyFont: any;
  children?: ReactNode;
}

const Layout: FunctionComponent<BaseLayoutProps> = ({
  titleFont,
  bodyFont,
  children,
}) => {
  return (
    <Providers>
      <main className={`${titleFont.className} ${bodyFont.className}`}>
        {children}
      </main>
    </Providers>
  );
};

export default Layout;
